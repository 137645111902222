@import '../../utils/Variables.scss';

.purchase-item-input-fields {
    padding-left: 12px;
    width: 200px;

    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
}