.coupon-campaign-section-input {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  margin-right: 10px;
  padding: 12px 8px;
}

.coupon-campaign-section-input:focus {
  border: 1px solid #c2c2c2;
  outline: none;
}

.coupon-campaign-section-caution-description {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  margin-top: 8px;
}
