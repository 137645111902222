@import '../../utils/Variables.scss';

.create-section-and-preview-container {
  margin-top: 20px;
  margin-bottom: 60px;
  justify-content: center;
}

.create-section-and-preview-container.row {
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
}

.create-section-container {
  padding: 0;
  width: 100%;
  min-width: 245px;
}

.create-step-section-item {
  background: white;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  padding: 30px 30px;
  flex-direction: column;
  display: flex;
}

.create-step-section-item-first-section {
  box-shadow: none;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px 0px rgba(154, 161, 171, 0.3);
}

.create-preview-col-section {
  max-width: 250px;
  padding: 0;
  top: 60px;
}
.create-preview-section {
  position: sticky;
  top: 60px;
}

.create-preview-section-safari {
  position: -webkit-sticky;
}
.create-preview-img {
  width: 100%;
  object-fit: contain;
  justify-content: flex-start;
  background-color: white;
}

.create-preview-title {
  width: 250px;
  text-align: left;
}

.content-no-preview {
  width: 100%;
  max-width: 1140px;
}
