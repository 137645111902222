@import '../../../utils/Variables.scss';
.create-coupon-step-container {
  max-width: 100%;
}
.preview-col-section {
  max-width: 21%;
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $darkBlue;
    margin-bottom: 8px;
  }
}
.preview-img {
  width: 100%;
}
.create-coupon-step1-container {
  padding: 30px 15px 110px;
}
.temp-content {
  padding-top: 90px;
}
