.feature-campaign-detail-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.banners-detail-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #404040;
  margin-bottom: 0px;
}
