@import '../../utils/Variables.scss';

.transaction-amount-total-value-label {
    font-family: $fontRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #404042;
    margin-right: 9px;
    margin-bottom: 0px;
}