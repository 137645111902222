.te-ww-container .tui-editor-contents {
  padding: 10px 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
}
.tui-editor-contents {
  min-height: 236px !important;
}

.tui-editor-contents h1,
.tui-editor-contents h2 {
  border-bottom: none;
}

.customer-editor-container {
  max-width: 700px;
  height: 272px;
}

.customer-editor-container-short {
  width: 619px;
  max-width: 100%;
}

.editor-container-error {
  border: 1px solid red;
}

.editor-container-error-message {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0000;
  padding: 0px;
  margin-top: 8px;
}
