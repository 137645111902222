@import '../../../utils/Variables.scss';
.next-step-prompt-container {
  min-width: 800px;
  min-height: 387px;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding-right: 40px;
  padding-top: 65px;
  padding-left: 40px;
  padding-bottom: 40px;
  margin: 0px;
  display: flex;
  flex-direction: column;

  .next-step-header {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 33px;
    color: #4d5560;
    margin-top: 40px;
    align-self: center;
  }

  .next-step-description {
    max-width: 387px;
    min-width: 387px;
    align-self: center;
    margin-top: 10px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $inkBlack;
    text-align: center;
    white-space: pre-wrap
  }

  .next-step-buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .next-step-first-button {
    align-self: center;
    background: $navyBlue;
    margin-right: 0px;
  }

  .next-step-link-button {
    margin-top: 20px;
    align-self: center;
    margin-right: 0px;
  }

  .modal-content {
    width: auto;
  }

  .modal-dialog {
    max-width: fit-content;
  }
}
